import React from 'react';
import { useState, useContext, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { MyContext } from "../MainContext";

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LoadingScreen from '../components/loading_screen';
import RefModal from '../components/reference';
import { useNavigate } from "react-router-dom";
import { NavItem } from 'react-bootstrap';
import Insight from '../components/insight';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderBlank, faFolderOpen, faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';

import SkeletonPost from '../components/skeleton';

import ShareModal from '../components/share_modal';

import Header from '../components/header';

import Footer from '../components/footer';

import SearchModule from '../components/search';

export default function ProfilePage () {

    const {setUser, user, setCurrentInsight} = useContext(MyContext);

    const { username } = useParams();

    const navigate = useNavigate();

    const [user_data, setUserData] = useState(false);
    const [insights, setInsights] = useState(null)
    const [reference, setReference] = useState(null)

    const [scrollPosition, setScrollPosition] = useState(false)
    const [has_More, setHasMore] = useState(true)

    const [is_loading, setIsLoading ] = useState(false)

    const [page, setPage] = useState(1)

    const [post_url, setPostUrl] = useState("")

    const [show_share_modal, setShareModal] = useState(false)

    const [is_insights, setIsInsights] = useState(5)
  
    const limit = 20;


      const close_ref_modal = () => {
     
        setReference(null)
        setShareModal(false)
        setPostUrl("")

    }


    const go_to_website = (url) => {

      window.open(url, '_blank');


    }
    

    const show_share = (url) => {

      setPostUrl(url)

   };
 
    useEffect(() => {

      if( post_url.length > 5 ){
    
          setShareModal(true)
    
      }
    
    }, [post_url]); 
    

 const refresh_insights = async () => {

        if(!username){
          return
        }
        
        let user_id = 0;

         if(user && user.user_id > 0){
            user_id = user.user_id;
         }

        try{  
          
          const response = await fetch('https://lomadek.org/api/schat/get_user_insights.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: username,
              user_id: user_id,
              page: page,
              limit: limit
            }),
          });
      
          const responseText = await response.json(); 
          //console.log('Raw response:', responseText);
  
      
           const data = responseText //JSON.parse(responseText);
           if(data.success && data.success == true){

                setIsInsights(10)

                    if (data.insights.length < limit) {
                        setHasMore(false);
                    }

                    if(!insights){

                        setInsights(data.insights)

                  }else{
                         
                         setInsights(prevInsights => {
                           // Create a set of existing insight IDs
                           const existingIds = new Set(prevInsights.map(insight => insight.id));
                           
                           // Filter out new insights that have IDs already in the existing set
                           const newInsights = data.insights.filter(insight => !existingIds.has(insight.id));
                           
                           // Concatenate filtered new insights with previous insights
                           return [...prevInsights, ...newInsights];
                       });
                  }
   
  
  
           }else{
  
             //setInsights(0)
             setHasMore(false);
             setIsInsights(0)
            
           }
  
        } catch (error) {
           console.error('Error sending request:', error);
        }
  
    
        
  };

  const refresh_user_data = async () => {

    let u_id = 0;

    if(!username ){
      return
    }

    if(user && user.user_id){

         u_id = user.user_id
    }
     
    try{  
      
      const response = await fetch('https://lomadek.org/api/schat/get_user_data.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          viewer: u_id
        }),
      });
  
      const responseText = await response.json(); 
      //console.log('Raw response:', responseText);

  
       const data = responseText //JSON.parse(responseText);
       if(data.success && data.success == true){

           setUserData(data)

        //setMsg({'msg': 'Insight published', 'mode': 'success'})


       }else{

         //setUserData(404)
        
       }

    } catch (error) {
       console.error('Error sending request:', error);

       //setUserData(404)
    }


    
};



const toggle_follow = async (user_id) => {

   if (is_loading ){

       return
   }

   setIsLoading(true)

    if(!user || !user.user_id){
      return
    }
     
    try{  

      const response = await fetch('https://lomadek.org/api/schat/toggle_follow.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          profile_id: user_id,
          my_id : user.user_id
        }),
      });
  
      const responseText = await response.json(); 
      //console.log('Raw response:', responseText);


       const data = responseText //JSON.parse(responseText);

       setIsLoading(false)

       if(data.success && data.success == true){

        let add_num = 1;

        if(data.follow === false){
           
              add_num = -1;
        }

        let following = parseInt(user.following) + add_num

        setUser( { ...user, following: following })


       }else{

          //
       }

    } catch (error) {

       console.error('Error sending request:', error);

       setIsLoading(false)

       //setUserData(404)
    }


    
};



const load_more = () => {

    const scrollPosition = window.scrollY;  // Save the current scroll position
    setScrollPosition(scrollPosition)
    setPage(prevPage => prevPage + 1);

  }


  useEffect(() => {

    setCurrentInsight({})

  }, []); 

  useEffect(() => {

    if(page > 0){

            refresh_insights()

    }

  }, [page]); 

  useEffect(() => {

    if(scrollPosition){

        window.scrollTo(0, scrollPosition); 

    }

  }, [insights]); 


      useEffect(() => {

        refresh_user_data() //visited ptofile's user
    
      }, [user]); 


      useEffect(() => {

       // console.log('user_data', user_data)

        if(user_data && user_data.user_id){

            refresh_insights()

        }

        
      }, [user_data]); 


      const toggle_like = async (insight_id, type) => {

        if(!user || !user.user_id){
          return
        }
         
        try{  
    
          const response = await fetch('https://lomadek.org/api/schat/toggle_like.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: user.user_id,
              insight_id : insight_id
            }),
          });
      
          const responseText = await response.json(); 
          //console.log('Raw response:', responseText);
    
    
           const data = responseText //JSON.parse(responseText);
           if(data.success && data.success == true ){

                
                let newTotalLikes = 0;
                let newUserLiked = 0;


                const insight = insights.find((insight) => insight.id === insight_id);

                  if (insight) {


                    if(data.like){

                      newTotalLikes = parseInt(insight.total_likes, 10) + 1;
                      newUserLiked = 1;

                    }else{

                      newTotalLikes = parseInt(insight.total_likes, 10) - 1;
                      newUserLiked = 0;

                    }

                  setInsights((prevInsights) =>
                    prevInsights.map((insight) =>
                      insight.id === insight_id
                        ? { ...insight, total_likes: newTotalLikes, user_liked: newUserLiked }
                        : insight
                    )
                  );
                };

               
                  
           }else{
    
              //
           }
    
        } catch (error) {
           console.error('Error sending request:', error);
    
           //setUserData(404)
        }
    
    
        
    };
    



  return (

    <div className="container">

       {!user_data.user_id ? (

        <LoadingScreen />

      ) : (
       
        <>
       {/* Top Navigation */}
       <Header />
         
     

      {/* Main Content */}
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <div className="col-md-8 col-sm-12 border p-3">

          <div className="profile-container">
                      <div className="profile-info d-flex justify-content-between align-items-start">
  {/* Profile Image and Edit Button */}
  <div className="d-flex align-items-center">
    <img
      src={user_data.photo || `${process.env.PUBLIC_URL}/avatar.jpg`}
      alt="Profile Image"
      className="profile-image"
      style={{ objectFit: "cover", marginRight: "10px" }}
    />
 
    {user && user.user_id > 0 && user.username !== user_data.username && <button className="edit-profile-btn m-3" onClick={()=>toggle_follow(user_data.user_id)}>{`${user_data.is_follow ? 'UnFollow' : 'Follow'}`}</button>}

    {!user || !user.user_id && <button className="edit-profile-btn m-3" onClick={()=>navigate("/")}>{'Follow'}</button>}

  </div>

  {/* Profile Details */}
  <div className="profile-details mt-4">
    <div className="profile-fullname" style={{  maxWidth: '100%' }}>
    {user_data.name}
  </div>
    <div className="profile-name">@{user_data.username}</div>
  </div>
</div>


                <div className="profile-bio">
                    <p>{user_data.bio}</p>
                    {user_data.website?.length > 4 &&  <span className="web-btn" onClick={() => go_to_website(user_data.website)}><FontAwesomeIcon icon={faGlobeAfrica} /> website</span>}
                </div>
                <div className="profile-stats">
                    <div className="followers">{user_data.followers} Follower{parseInt(user_data.followers) > 1 ? 's' : ''}</div>
                    <div className="following">{user_data.following} Following</div>
                </div>
            </div>



            
<Tabs defaultActiveKey="for-you" id="myTabs" className="justify-content-between">

      <Tab eventKey="for-you" title="Insights">
        <div className='mt-4'>
        {insights !== null && insights !== 0 &&

          insights.map(insight => (

            <Insight share = {show_share} key={insight.id} insight={insight} setReference={setReference}  toggle_like={toggle_like} type={'following'}  setInsight={setCurrentInsight} />

          ))
                  
        }

        {is_insights < 1 && 

            <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh', textAlign: 'center' }}>
            <FontAwesomeIcon icon={faFolderOpen} size="4x" className="mb-3 text-dark" />
            <h5>No insights yet.</h5>
            </div>

        
        }

            {insights !== null && has_More &&  <div className="loadmore text-center "  onClick={load_more}>
                        <p>Load more insights</p>
        </div> }
        
        {<div style={{marginBottom: '90px'}} />}

        </div>
      </Tab>

    </Tabs>

          </div>
          <div className="col-md-4 d-none d-md-block border p-3">
            <SearchModule />
          </div>
        </div>
      </div>

      <Footer />
      </>

      )}

      {reference !== null && <RefModal data={reference}  close ={close_ref_modal} />}
      {show_share_modal && <ShareModal post_url={post_url} close ={close_ref_modal} />}

    </div>
  );
};

