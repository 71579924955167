import React from 'react';
import { useState, useContext, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,  faWarning, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from "react-router-dom";
import ThemeToggle from './toggle_theme';


const SearchModule = ({query = false}) => {

    const navigate = useNavigate();

    const [input, setInput] = useState('');
    const [results, setResults] = useState([]);
    const [type, setType] = useState('insight');
    const [msg, setMsg] = useState('');

  
    const handleChange = async (e) => {
        const query = e.target.value;
        setInput(query);

        setMsg({})

        if(query?.length < 2){

           setResults([])
            return
        }
         

         fetch_result(query)

    };


    const go_to_search = () => {

          navigate(`/search/${input}`)
    }


    const fetch_result = async(query) => {

          
      try{  

        const response = await fetch('https://lomadek.org/api/schat/search.php', {
          method: 'POST',
          headers: {

            'Content-Type': 'application/json', // Change content type to application/json
          },
          body: JSON.stringify({
            keyword:query,
            limit:2,
            page: 1
          }),
        });
    
        const responseText = await response.json(); // Log the raw response text
       // console.log('Raw search response:', responseText);

    
         const data = responseText //JSON.parse(responseText);

         if(data.success && data.success == true){

            setType(data.type);

            setMsg({'msg': `${data.result[0].total_count} result${data.result[0].total_count > 1 ? "s" : ""} found for ${query}`, 'mode': 'success'})

          
                    setResults(data.result);


         }else{

          setMsg({'msg': `0 result found for ${query}`, 'mode': 'danger'})

          setResults([]);

         // setHasMore(false)

          
         }

      } catch (error) {
         
        setResults([]);

        setMsg({'msg': 'Error running search. Please check your internet connection and retry.' , 'mode': 'danger'})

         console.error('Error sending request:', error);
      }

    }

    return (
        <div className="search-component">
            <div className="search-box">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input

                    id="search1"
                    type="text"
                    value={input}
                    onChange={handleChange}
                    placeholder="Search..."
                />
            </div>

            {msg?.msg && <span style={{fontSize:'smaller'}} className={`text-${msg.mode}`}>{msg.mode === "danger" ?( <FontAwesomeIcon icon={faWarning} />) : (<FontAwesomeIcon icon={faCheckCircle} />)} {msg.msg}</span>}

            {results?.length < 1 && (
                <div className="results-help text-justify my-4 refs-div" style={{fontSize:'smaller'}}>
                    <h6><b>How to Use Search</b></h6>
                    <hr/>
                    <p><b>Search for a Bible Verse</b><br/>e.g Esther 1:1, Genesis 3:4-5<br/><br/>
                    <b>Searching For a Username</b><br/>Start search with "@" e.g @pastor_sam<br/><br/>
                    <b>Searching a keyword</b><br/>Enter keyword e.g overcometh</p>
                </div>
                
            )}

            {results?.length > 0 && (

                <div className="results_ my-4">
                   
                   <button onClick={go_to_search} className='btn btn-block btn-primary b-primary w-100'>

                        <FontAwesomeIcon icon={faSearch} />
                        <span style={{marginLeft:12}}>{`See Results for`} <b>{`${input.substring(0, 10)}${input.length > 10 ? "..." : ""}"`}</b></span>
                    </button>

                </div>
            )}
            
            <p style={{marginTop: 80}}></p>

            <ThemeToggle />

            
            <div className="results-help text-justify my-4 refs-div" style={{fontSize:'smaller'}}>
                
                    <h6><b>Need support?</b></h6>
                    <hr/>
                    <p>Send an email to <b>support@clowza.com</b> or <b>clowzamail@gmail.com</b></p>
                </div>



            
        </div>
    );
};

export default SearchModule;
