import React from 'react';
import { useState, useContext, useEffect, useCallback } from "react";
import { useParams, Link } from 'react-router-dom';
import { MyContext } from "../MainContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {faBook, faComment, faShare, faPaperPlane,  faHeart, faTrash, faEllipsisV, faEdit  } from '@fortawesome/free-solid-svg-icons';

import { Dropdown } from 'react-bootstrap';

import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';

import LoadingScreen from '../components/loading_screen';
import RefModal from '../components/reference';
import { useNavigate } from "react-router-dom";
import { openReference, timeAgo } from '../utils/functions';
import DeleteModal from '../components/delete';

import Header from '../components/header';

import LoadMore from '../components/load_more';
import SearchModule from '../components/search';

import ShareModal from '../components/share_modal';
import Footer from '../components/footer';

export default function InsightPage () {

    const {setUser, user, current_insight} = useContext(MyContext);

    const { username, insight_id } = useParams();
  
    const navigate = useNavigate();

    const [insight, setInsight] = useState(null)
    const [reference, setReference] = useState(null)
    const [is_comment, setIsComment] = useState(false)
    const [comments, setComments] = useState(null)
    const [comment, setComment] = useState("")
    const [likes, setLikes] = useState(0)

    const [show_delete, setShowDelete] = useState(false)

    const [scrollPosition, setScrollPosition] = useState(false)
    const [has_More, setHasMore] = useState(true)

    const [comment_id, setCommentID] = useState(0)

    const [page, setPage] = useState(1)

    const [del_msg, setDelMsg] = useState({})

    const [is_delete, setIsDelete] = useState(false)

    const [is_edit, setIsEdit] = useState(false)

    const [post_url, setPostUrl] = useState("")
    const [show_share_modal, setShareModal] = useState(false)
 
    const limit = 20;

    const p_url = "https://clowza.com";


    const handleCommentChange = useCallback((e) => {

        setComment(e.target.value);

      }, []);

      const close_ref_modal = () => {
     
        setReference(null)
        setShareModal(false)
        setPostUrl("")
    }


    const show_share = (url) => {

      setPostUrl(url)

   };




  const toggle_comment = () => {

       setIsEdit(false)
       setCommentID(0)
       setComment("")

       setIsComment(!is_comment) 
  }
 
    //console.log('us', user)

 const get_insight = async () => {

 // console.log("usn", username, insight_id)

        if(!username){
          return
        }

        let u_id = 0;

         if(user && user.user_id){

             u_id = user.user_id;
         }

        try{  
          
          const response = await fetch('https://lomadek.org/api/schat/get_user_insight.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: username,
              insight_id: insight_id,
              user_id : u_id
            }),
          }); 
      
          const responseText = await response.json(); 
          //console.log('Raw response:', responseText);
  
      
           const data = responseText //JSON.parse(responseText);
           //console.log(data)
           if(data.success && data.success == true){

               setInsight(data.insight)
  
          //console.log('passed insight', data.insight)


           }else{
  
             //setInsight(0)
            
           }
  
        } catch (error) {
           console.error('Error sending request:', error);
        }
  
    
        
  };


  const toggle_like = async () => {

    if(!user || !user.user_id || !insight){
      return
    }
     
    try{  

      const response = await fetch('https://lomadek.org/api/schat/toggle_like.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.user_id,
          insight_id : insight.id
        }),
      });
  
      const responseText = await response.json(); 
      //console.log('Raw response:', responseText);


       const data = responseText //JSON.parse(responseText);
       if(data.success && data.success == true ){

            
            let newTotalLikes = 0;
            let newUserLiked = 0;


                if(data.like){

                  newTotalLikes = parseInt(insight.total_likes, 10) + 1;
                  newUserLiked = 1;

                }else{

                  newTotalLikes = parseInt(insight.total_likes, 10) - 1;
                  newUserLiked = 0;

                }

              setInsight( { ...insight, total_likes: newTotalLikes, user_liked: newUserLiked })
           
       }else{

          //
       }

    } catch (error) {
       console.error('Error sending request:', error);

       //setUserData(404)
    }


    
};


const close_modal = () => {

     setIsEdit(false)
     setCommentID(0)
     setShowDelete(false) 
     setIsComment(false)
}

const init_delete = (id) => {

  setIsEdit(false) 
  setIsComment(false) 

  //console.log('init_delete', id)
   
  setCommentID(id)

}



const edit_comment = (id, cmt) => {

   //console.log('edit clicked', id, cmt)

   setComment(cmt)

   setIsEdit(true)

   setCommentID(id)

   setIsComment(true) 
   
}



  const get_comments = async (refresh = false) => {

    if(!username){
      return
    }
     
    try{  
      
      const response = await fetch('https://lomadek.org/api/schat/get_comments.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          insight_id: insight_id,
          page: page,
          limit: limit
        }),
      });
  
      const responseText = await response.json(); 
      //.log('Raw response:', responseText);

  
       const data = responseText //JSON.parse(responseText);
       //console.log(data)
       if(data.success && data.success == true){

           if (data.comments.length < limit) {

            setHasMore(false);

          }else{
            
            setHasMore(true)

        }
          
          if(!comments || refresh){

              setComments(data.comments)

            }else{
                  
                  setComments(prevComments => {
                    // Create a set of existing insight IDs
                    const existingIds = new Set(prevComments.map(comment => comment.id));
                    
                    // Filter out new insights that have IDs already in the existing set
                    const newComments = data.comments.filter(comment => !existingIds.has(comment.id));
                    
                    // Concatenate filtered new insights with previous insights
                    return [...prevComments, ...newComments];
                });
            }


        //setMsg({'msg': 'Insight published', 'mode': 'success'})

       }else{

        setHasMore(false)
        
       }

    } catch (error) {

       console.error('Error sending request:', error);
    }


    
};

const post_comment = async () => {

  //console.log(comment, user.username, comment_id, is_edit)

    if(!username || !comment || !user || !user.username || comment.length < 2){
      return
    }

    
    try{  
      
      const response = await fetch('https://lomadek.org/api/schat/post_comment.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          insight_id: insight_id,
          user_id : user.user_id,
          comment: comment,
          comment_id : (comment_id > 0) ? comment_id : 0
        }),
      });
  
      const responseText = await response.json(); 
      //console.log('Raw response:', responseText);

  
       const data = responseText //JSON.parse(responseText);
       //console.log(data)

       if(data.success && data.success == true){

           setIsComment(false)
           get_comments(true)

       }else{

         //setIsComment(false)
        
       }

    } catch (error) {

       console.error('Error sending request:', error);
    }


    
};


const delete_comment = async () => {

  if(is_delete){

      return
  }

  setDelMsg({})

  setIsDelete(true)

  //console.log('cm_id',comment_id)

  if(!username || !user || !user.username){

    return
  }
   
  try{  
    
    const response = await fetch('https://lomadek.org/api/schat/delete_comment.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        comment_id: comment_id,
      }),
    });

    const responseText = await response.json(); 
    //console.log('Raw response:', responseText);


     const data = responseText //JSON.parse(responseText);

     setIsDelete(false)

     //console.log(data)
     if(data.success && data.success == true){

         get_comments(true)

         close_modal()

 
     }else{

         get_comments(true)

         setDelMsg({"message": "Insight not deleted due to an error. please try again.", "mode": "danger"})
      
     }

  } catch (error) {

    setIsDelete(false)
    
    setDelMsg({"message": "Comment not deleted please check your internet and retry.", "mode": "danger"})

     console.error('Error sending request:', error);
  }


  
};


const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

      useEffect(() => {

        //console.log('passed insight', current_insight)

       if(!isEmpty(current_insight) ){
            
            setInsight(current_insight)

       }else{
          
            get_insight()

       }

           
            get_comments()

      }, []); 




      useEffect(() => {

        if(comment_id > 0 && !is_edit){

          setShowDelete(true)

        }


  }, [comment_id, is_edit]); 

   
  useEffect(() => {

    if( post_url.length > 5 ){
  
        setShareModal(true)
  
    }
  
  }, [post_url]); 
  
  

      useEffect(() => {
          
          get_comments()
          //setComment("")

  }, [is_comment]);


  const open_ref = async (ref) => {


    const ref_ = await openReference(ref)

    if(ref_){
       
        setReference(ref_)
    }

}

 


const Comment = ({ id, userImage, username, timeAgo, commentText }) => {

        return (
          <div className="container my-3 w-100 border-bottom_"  style={{padding: '12px 0'}}>
            <div className="row">
              <div className="col-auto" onClick={() => navigate(`/${username}`)}>
                <img
                  src={userImage || `${process.env.PUBLIC_URL}/avatar.jpg`}
                  alt={`@${username}`}
                  className="rounded-circle"
                  width="20"
                  height="20"
                  style={{height: '30px', width:'30px'}}
                />
              </div>
              <div className="col comment-content">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0 sc-text-muted txt-btn">{`@${username}`}</h6>
                  
                  <small className="sc-text-muted" style={{marginLeft:'12px'}}>{timeAgo}</small>

                  {username === user.username &&  


                      <Dropdown className="ms-auto">
                        <Dropdown.Toggle variant="link link-dark" bsPrefix="p-0">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="end">
                          <Dropdown.Item onClick={() => edit_comment(id, commentText)}>
                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                            Edit
                          </Dropdown.Item>

                          <Dropdown.Item onClick={() => init_delete(id)} >
                            <FontAwesomeIcon icon={faTrash} className="me-2" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                  }

                </div>
                <p className="mb-0">{commentText}</p>
              </div>
            </div>
          </div>
        );
      }


  const load_more = () => {

        const scrollPosition = window.scrollY;  // Save the current scroll position
        setScrollPosition(scrollPosition)
        setPage(prevPage => prevPage + 1);
    
      }

    
      useEffect(() => {

        if(page > 0){
    
           get_comments()
    
        }
    
      }, [page]); 
    
      useEffect(() => {
    
        if(scrollPosition){
    
            window.scrollTo(0, scrollPosition); 
    
        }
    
      }, [comments]); 

  return (

    <div className="container">

       {!insight ? (

        <LoadingScreen />

      ) : (
       
        <>
       {/* Top Navigation */}
       <Header />

      {/* Main Content */}
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <div className="col-md-8 col-sm-12 border p-3">

  


        <div className='mt-4'>
        {insight !== null && insight !== 0 &&

<div className=" mb-4 pb-4">
<div className='d-flex pb-4 border-bottom'>
<div className="me-3" onClick={()=> navigate(`/${insight.username}`)}>
  <img src={insight.photo || `${process.env.PUBLIC_URL}/avatar.jpg`} alt="Profile" className="rounded-circle" />
</div>

<div  className="w-100 ">
  
  <div className="d-flex align-items-center">
    <span className="sc-text-muted me-2" onClick={()=> navigate(`/${insight.username}`)}>{`@${insight.username}`}</span>
    <span className="sc-text-muted">{timeAgo(insight.time_posted)}</span>
  </div>

  {insight.passages.length >  5 && 
  
  <div className="d-flex_ justify-content-between sc-text-muted my-2">

      {JSON.parse(insight.passages).map((ref, index) => (

      <span key={index} className="b-primary" style={{ display: 'inline-block', padding: '0px 5px', margin: '2px 0',  alignItems: 'center', marginRight:'15px', borderRadius:'7px' }}>

            <span  onClick={() => open_ref(ref)} style={{color:'white'}}>  {ref.book} {ref.chapter}:{ref.verse1}{`${ref.verse2 > ref.verse1? `-${ref.verse2}` : ''}`}</span>

          <FontAwesomeIcon
              icon={faBook}
              style={{ cursor: 'pointer', color: 'white' , marginLeft:'10px', fontSize:'smaller'}}
          />
      </span>
      ))}
  </div>
 }

  <div className="mt-2" style={{marginLeft: "-50px"}}>
    <p>{insight.insight}</p>
  </div>

{user && user.user_id > 0 ? (

  <div className="d-flex justify-content-between sc-text-muted mt-2 insight-icons-div " style={{marginLeft: "-50px"}} >
    <span onClick={()=> toggle_comment()}> <FontAwesomeIcon icon={faComment} /> {comments?.length || 0}</span>
    <span onClick={()=>toggle_like(insight.id)}> <FontAwesomeIcon style={{color: insight.user_liked > 0 ? '#48887b': 'unset'}} icon={insight.user_liked > 0 ? faHeart : farHeart} /> {insight.total_likes}</span>
    <span onClick={()=>show_share( `${p_url}/${insight.username}/insight/${insight.id}`)}> <FontAwesomeIcon icon={faShare} /> {'Share'}</span>
  </div>

  ):
  
  (

    <div className="d-flex justify-content-between sc-text-muted mt-2 insight-icons-div " style={{marginLeft: "-50px"}} >
    <span onClick={()=> navigate("/")}> <FontAwesomeIcon icon={faComment} /> {comments?.length || 0}</span>
    <span onClick={() => navigate("/")}> <FontAwesomeIcon icon={faHeart} /> {insight.total_likes}</span>
    <span onClick={() => navigate("/")}> <FontAwesomeIcon icon={faShare} /> {'Share'}</span>
   </div>

  )

  }

</div>
</div>
    
{user && user.user_id > 0 && is_comment && <div id="comment-box" className="my-3 p-3 border fixed-bottom comment-box">

    <div className="d-flex align-items-end">
        
      <textarea
        className="form-control"
        id="comment-input"
        rows="1"
        placeholder="Type your comment..."
        style={{ resize: 'none' }}

        value={comment}

         onChange={handleCommentChange}

      ></textarea>
      <button className="btn btn-primary b-primary m-3">
        <FontAwesomeIcon icon={faPaperPlane}  onClick={()=> post_comment()}/>
      </button>
    </div>
</div>}

{comments?.length > 0 &&

<div className="comments">


       {comments.map((comment, index) => (
           
            <Comment

            key={comment.id}
            userImage={comment.photo}
            username={comment.username}
            timeAgo={timeAgo(comment.date_posted)}
            commentText={comment.content}
            id = {comment.id}
        />

      ))}



  {has_More &&
                  <LoadMore  func={() => load_more()}   text="Load more Comments" />
                  
                  }
      
  
 
</div> }

</div>
            
        }
        </div>
    

          </div>
          <div className="col-md-4 d-none d-md-block border p-3">
            <SearchModule />
          </div>
        </div>
      </div>

      {/* Bottom Navigation for Mobile */}
      <Footer />
      </>

      )}

      {reference !== null && <RefModal data={reference}  close ={close_ref_modal} />}
      {show_share_modal && <ShareModal post_url={post_url} close ={close_ref_modal} />}

      {show_delete  && <DeleteModal text={'Are you sure you want to delete this comment? \n this account is irreversible.'} close ={close_modal} delete_fn={delete_comment} id={comment_id}  del_msg ={del_msg} />}


    </div>
  );
};

