import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';


function RequestResetModal({ close, show }) {


  const [email, setEmail] = useState('');

  const [is_loading, setIsLoading] = useState(false);

  const [msg, setMsg] = useState(null);


  const show_login = () => {

    close("login")
    
  }


  const requestResetLink = async () => {

    if(is_loading){

      //console.log('mt click ignored..')

      return
    }

    setIsLoading(true)

      try{  
        
        const response = await fetch('https://lomadek.org/api/schat/request-reset-link.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Change content type to application/json
          },
          body: JSON.stringify({
            email: email,
          }),
        });
    
        const responseText = await response.json(); // Log the raw response text
        //console.log('rvalidate reset link raw response:', responseText);

         const data = responseText //JSON.parse(responseText);

         setIsLoading(false)

         if(data.success && data.success == true){

           setMsg({'message': 'Please check your email for a link to reset your password', 'mode': 'success'})


         }else{

            setMsg({'message': data.messages , 'mode': 'danger'}) 
          
         }

      } catch (error) {
        setIsLoading(false)
         console.error('Error sending request:', error);
         setMsg({'message': 'Error sending request. Please check your internet connection and retry.' , 'mode': 'danger'}) 
      }

  
      
};
  
  
  
const handle_close =()=>{
    setMsg(null)
    close()
}


  const handleSubmit = (e) => {
    e.preventDefault();
      requestResetLink()
    //console.log({ email });
  };

  return (
    <div
    className={`modal ${show ? ' show' : ''}`}
    tabIndex="-1" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Request Password Reset</h5>
            <button type="button" className="btn-close" onClick={handle_close}></button>
          </div>
          <div className="modal-body">
  

            <form onSubmit={handleSubmit}>

                
            {msg &&
                     <div className="mb-3">
                     <div className={`alert alert-${msg.mode} d-flex align-items-center`} role="alert">
                     {msg.mode === "danger" ? (
                                    <i className="fas fa-exclamation-triangle fa-2x mr-3"></i>
                                ) : (
                                    <i className="fas fa-check-circle fa-2x mr-3"></i>
                                )}
                    <div style={{marginLeft: '14px'}}>
                                   {msg.message}
                               </div>
                               </div>
                     </div>
                }
            { (msg == null || (msg.mode && msg.mode == "danger")) &&
             <>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Enter Email:</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="mt-3 btn btn-primary b-primary w-100">Request reset link</button>
              </>

            }

            </form>
    


          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestResetModal;
