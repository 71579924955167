import React from "react";
import { useParams, Link } from 'react-router-dom';
import { MyContext } from "../MainContext";
import { useState, useContext, useEffect, useRef} from "react";
//import jwt from 'jsonwebtoken';
import { useNavigate } from "react-router-dom";
//mport { cookieParser } from "../../utils/functions";
import { GoogleLogin } from '@react-oauth/google';

import SignUpModal from "../components/signup";
import LogInModal from "../components/login";
import ResetModal from "../components/reset";
import RequestResetModal from "../components/forgot_password";
 
import BibleVerses from "../components/random_verses";
import LoadingScreen from "../components/loading_screen";

export default function AuthPage() { 

    const {setUser, user} = useContext(MyContext);

    const navigate = useNavigate();

    const { email, passcode } = useParams();

       
    const [toggleState, setToggleState] = useState("show")
    const [openSignUpModal, setOpenSignUpModal] = useState(false);
    const [openLogInModal, setOpenLogInModal] = useState(false);

    const [openResetModal, setOpenResetModal] = useState(false)
    const [openRequestResetModal, setOpenRequestResetModal] = useState(false)

    const [buttonWidth, setButtonWidth] = useState(250);

    const [auth_passed, setAuthPassed] = useState(false);

/* 
    const[storedUser, setStoredUser] = useState(localStorage.getItem('sc_chat'))

    console.log('stored user', storedUser)
    
    if (storedUser === 'true') {
  
      setUser(storedUser);
  
      //navigate("/")
  
    } */
  
    const google_auth = async (userData) => {

      
      setAuthPassed(true)
       
        try{  
          
          const response = await fetch('https://lomadek.org/api/schat/google-auth.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Change content type to application/json
            },
            body: JSON.stringify({
              first_name: userData.given_name,
              last_name: userData.family_name,
              username: userData.name,
              email: userData.email,
            }),
          });
      
          const responseText = await response.json(); // Log the raw response text
          //console.log('Raw response:', responseText);
  
      
           const data = responseText //JSON.parse(responseText);
           if(data.success && data.success === true){
  
            //console.log(data)

            setUser(data)
  
  
           }else{
  
            setAuthPassed(false)
            
           }
  
        } catch (error) {
           console.error('Error sending request:', error);
        }
  
    
        
  };
  
    //console.log(email, passcode)
    
    const responseMessage = async (response) => {
        try {
          // Extract the credential (JWT token) from the response
          const { credential } = response;
      
          // Make a POST request to Google's token validation endpoint
          const tokenValidationResponse = await fetch(`https://oauth2.googleapis.com/tokeninfo?id_token=${credential}`);
          const userData = await tokenValidationResponse.json();
      
          // Retrieve user information from the token validation response
          //const { name, email, family_name, given_name } = userData;

           google_auth(userData)
  

        } catch (error) {
          console.error('Error storing user data:', error);
        }
      };
      
      
    const errorMessage = (error) => {
        console.log(error);
    };
  


    useEffect(() => {

      //console.log('tg mode', localStorage.getItem('darkMode'))
  
      const storedDarkMode = localStorage.getItem('darkMode');
      
      if (storedDarkMode === 'true') {
        document.body.classList.add('dark-mode');
      }
  
    }, []);


 useEffect(() => {

  if(email && passcode){
     
      setOpenResetModal(true)
  }

 }, [])


 // Function to close the modal
 const closeModal = (modal = null) => {

    setOpenLogInModal(false)
    setOpenSignUpModal(false)
    setOpenResetModal(false)
    setOpenRequestResetModal(false)

    if(modal && modal !== null){

        if(modal === "login"){

            setOpenLogInModal(true)

        }else if(modal === "signup"){

            setOpenSignUpModal(true)

        }else if(modal === "reset"){

          setOpenRequestResetModal(true)

      }else{

            //
        }
    }

 };

 useEffect(() => {

  const updateButtonWidth = () => {

      if (window.innerWidth <= 768) {
          setButtonWidth(200); 
      } else {
          setButtonWidth(380); 
      }
  };

  updateButtonWidth();

  window.addEventListener('resize', updateButtonWidth);

  return () => window.removeEventListener('resize', updateButtonWidth);
}, []);

   

    useEffect(()=>{

        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  
        if(width < 993){

            setToggleState('hide')        
        
        }else{
                
                setToggleState('show')
        }
        
            
      },[])
    
  useEffect(()=>{
  
   
        
  },[toggleState])

  return (
<>
 {auth_passed ? (

    <LoadingScreen />

 )
:(<div className="container-fluid full-height">
    <div className="row my-5 full-height">

      <div className="col-md-6 d-flex flex-column justify-content-center hm-cols" >
        <div className="centered-image text-center" >
          <img src={`${process.env.PUBLIC_URL}/logo_hybrid_vertical.svg`} alt="Placeholder Image" className="img-fluid hm-logo" style={{maxWidth:'250px'}} />
        </div>

        <BibleVerses />

      </div>

      <div className="col-md-6 hm-cols text-center">
        <div id="mn-div" >
          <h3>Connect with a global community of believers, bound by a shared journey of spiritual growth.</h3>
          <br/>

  
            <GoogleLogin 
            onSuccess={responseMessage} 
            onError={errorMessage}  
            size="large"  
            theme='filled_blue' 
            width={buttonWidth}    
           />
         
          <br/>

          <p style={{fontSize:'smaller'}}>or</p>

          <button type="button" className="btn btn-lg_ btn-primary w-100 my-1 b-primary"  onClick={()=> setOpenSignUpModal(true)} >Create Account</button>
          
        
          <p style={{marginTop:'14px' , fontSize:'smaller'}}>Already have an account?</p>
          <button type="button" className="btn btn-lg_ btn-secondary w-100" onClick={() => setOpenLogInModal(true)}>Sign In</button>
        </div>
      </div>
    </div>

    <SignUpModal show = {openSignUpModal} close ={closeModal} />

    <LogInModal show = {openLogInModal} close ={closeModal}  setUser={setUser} />

    <ResetModal show = {openResetModal} close ={closeModal} passcode={passcode} email={email} />

    <RequestResetModal show = {openRequestResetModal} close ={closeModal} />

    

    
    <footer className="bg-light text-center py-3">
  <div className="container">
    <div className="row">
      <div className="col-12">
        {/* Navigation Links */}
        <nav className="footer-nav">
          <ul className="list-inline">
            <li className="list-inline-item txt-btn" style={{marginRight:42, cursor:'default'}}><a onClick={()=> navigate('/privacy-policy')}>Privacy policy</a></li>
            <li className="list-inline-item txt-btn" style={{ cursor:'default'}}><a onClick={()=> navigate('/about')}>About us</a></li>
          </ul>
        </nav>
        {/* Copyright */}
        <p className="txt-btn" >&copy; {`${(new Date()).getFullYear()}`} Clowza.com. All rights reserved.</p>
      </div>
    </div>
  </div>
</footer>


  </div>)}
  
 

</>
    
  );
}
