import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCamera, faCheckCircle, faTrashAlt, faWarning } from '@fortawesome/free-solid-svg-icons';
import { isValidEmail } from '../utils/functions';


function SignUpModal({ close, show }) {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const [is_done, setDone] = useState(false);

  const [error, setError] = useState({});

  const [is_loading, setISLoading] = useState(false)

  const [email_msg, setEmailMsg] = useState({})

  const [username_msg, setUsernameMsg] = useState({})

  const [username_pass, setUsernamePass] = useState(true)

  const [msg, setMsg] = useState(null);


  const close_modal = () => {
      
       setDone(false)

       close()

     }


  const pass_username = async(query) => {

    setUsernameMsg({})

   if(!query || query.length < 2){

    setUsernameMsg({"msg" : "Profile ID should be between 3 to 20 chars", "type": "danger"});

     return
   }
  try{  

    const response = await fetch('https://lomadek.org/api/schat/pass_username.php', {
      method: 'POST',
      headers: {

        'Content-Type': 'application/json', // Change content type to application/json
      },
      body: JSON.stringify({
        username:query
      }),
    });

    const responseText = await response.json(); // Log the raw response text

    //console.log('Raw search response:', responseText);

     const data = responseText //JSON.parse(responseText);

     if(data.success && data.success == true){

        setUsernamePass(false);

        setUsernameMsg({"msg" : `${data.username} is not available`, "type" : "danger"});

     }else{

      setUsernamePass(true);

      setUsernameMsg({"msg" : `${data.username} is available`, "type" : "success"});

     }

  } catch (error) {
     
     setUsernamePass(false);

     setUsernameMsg({"msg" : `Error validating ${username}. Please check your internet connection and retry.`, "type" : "danger"});

     console.error('Error sending request:', error);
  }

}


  const show_login = () => {

    close("login")
    
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    setMsg({})

    setISLoading(true)

    if(is_loading){

         return
    }

    //console.log({ firstName, lastName, username, dob });

    if(firstName.length > 70 || lastName.length > 70 || username.length < 3 || username.length > 30 || !username_pass || email.length > 200 || email.length < 9 || firstName.length < 3 || lastName.length < 3 ){

        setMsg({'msg': 'Please review form for errors and retry.', 'mode': 'danger'})

        return

    }
     
      try{  
        
        const response = await fetch('https://lomadek.org/api/schat/register.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Change content type to application/json
          },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            username: username,
            email: email,
            dob: ""
          }),
        });
    
        const responseText = await response.json(); // Log the raw response text
        //console.log('Raw response:', responseText);

    
         const data = responseText //JSON.parse(responseText);

         setISLoading(false)

         if(data.success && data.success == true){

             setMsg({})

             setDone(true)

         }else{

            setMsg({'msg': data.messages, 'mode': 'danger'})

          
         }

      } catch (error) {

        setISLoading(false)

         setMsg({'msg': 'Error submiting form. Please check your internet and retry.', 'mode': 'danger'})

         console.error('Error sending request:', error);
      }

  
      
};


const checkUsername = async (e) => {

  setUsernameMsg({});

  const value = e.target.value;

  const regex = /^[a-zA-Z._-]*$/; // Regular expression to allow only letters, ., _, and -
  

if (!regex.test(value)) {

    setUsernameMsg({"msg" : `Invalid character (${value.replace(username, "")}) entered. use only [ a-z _ - . ]`, "type": "danger"});

    return

  }else if (value.length > 0 && (value.length > 20 || value.length < 3 )  ) {

    setUsernameMsg({"msg" : "Profile ID should be between 3 to 20 chars", "type": "danger"});


  }else if (value < 1) {

     //


  }else{
      
        pass_username(value)

  }

   setUsername(value);


};


const checkEmail = async (e) => {

  setEmailMsg({});

  const value = e.target.value;


if (!isValidEmail(value)) {

    setEmailMsg({"msg" : `Invalid Email address.`, "type": "danger"});


  }else if (value.length > 200) {

    setEmailMsg({"msg" : "Email address should be between 9 to 200 chars", "type": "danger"});


  }

  setEmail(value);

};





  return (
    <div
    className={`modal ${show ? ' show' : ''}`}
    tabIndex="-1" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Account</h5>
            <button type="button" className="btn-close" onClick={close_modal}></button>
          </div>
          <div className="modal-body">

            {!is_done && <form onSubmit={handleSubmit}>


            <div className="mb-3 p-2 border rounded-3 ep-form">

            <div className='row'>

            <div className='col-6'>
                <label htmlFor="firstName" className="form-label">First Name <span style={{marginLeft:7}} className={`text-${(firstName.length > 70 || firstName.length < 3) ? 'danger' : 'success'}`}>{`${firstName.length} / 70`}</span></label>
                <input
                type="text"
                className="form-control"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                />
                </div>

                <div className='col-6'>
                <label htmlFor="lastName" className="form-label">Last Name <span style={{marginLeft:7}} className={`text-${(lastName.length > 70 || lastName.length < 3) ? 'danger' : 'success'}`}>{`${lastName.length} / 70`}</span></label>
                <input
                type="text"
                className="form-control"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                />
                </div>
    </div>
</div>
  

<div className="mb-3 p-2 border rounded-3 ep-form">
                    <label htmlFor="location" className="form-label">Profile ID {!username_msg.msg &&<span style={{marginLeft:7}} className={`text-${(username.length > 30 || username.length < 3) ? 'danger' : 'success'}`}>{`${username.length} / 30`}</span>} {username_msg && username_msg.type &&  <span style={{marginLeft:7}} className={`text-${username_msg.type}`}> <FontAwesomeIcon icon={username_msg.type === "success" ? faCheckCircle : faWarning}/> {username_msg.msg}</span>}</label>
                    <input
                    type="text"
                    className="form-control"
                    id="location"
                    value={username}
                    onChange={checkUsername}
                    required
                    />
                </div>

  
 <div className="mb-3 p-2 border rounded-3 ep-form">
                    <label htmlFor="email_" className="form-label">Email Address {email_msg && email_msg.type && <span style={{marginLeft:7}} className={`text-${email_msg.type}`}> <FontAwesomeIcon icon={email_msg.type === "success" ? faCheckCircle : faWarning}/> {email_msg.msg}</span>}</label>
                    <input
                    type="text"
                    className="form-control"
                    id="email_"
                    value={email}
                    onChange={checkEmail}
                    required
                    />
                </div>

                {msg && msg.msg && <span className={`text-${msg.mode}`}><FontAwesomeIcon icon={msg.mode === "success" ? faCheckCircle : faWarning}/> {msg.msg}</span>}


              <button type="submit" className="mt-3 btn w-100 btn-primary b-primary">Sign up</button>

              <button type="button" className="mt-3 btn w-100 txt-btn" onClick={show_login}>Sign in</button>

            </form>}

           {is_done && 

             <div className="container mt-5">

             <div className="row justify-content-center">

                 <div className="col-md-6 text-center mb-3">

                     <i className="fas fa-check-circle fa-5x text-success mb-4"></i>
                     <h2 >Yay! Your account has been created</h2>
                     <h4 className='mt-3'>Please <b>check your email</b> for a link to activate your account.</h4>

                     <button type="button" className="mt-3 btn w-100  btn-primary b-primary d-none" onClick={show_login}>Sign in</button>

                 </div>
             </div>
         </div> 
        }

          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpModal;
